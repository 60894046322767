<template>
	
	<BackgroundPage backgroundColor="var(--lastAbout)"/>
	<PageHeader pageName="contact" pageColor="var(--lastAbout)" ></PageHeader>
	
	<main class="text-center text-white contact-main m-auto w-100 h-100 d-flex">
		<div class="m-auto contact-div">
			<div class="contact-title anim-1">Manager</div>
			<div class="contact-name">Piotr Nehrebecki - Falkiewicz</div>
			<div class="anim-2">
				<a class="contact-phone contact-name" href="tel:+48501033220">+48 501 033 220</a>
			</div>
			<!-- <div class="contact-name" href="tel:+48501033220">+48 501 033 220</div> -->
			<div class="anim-2">
				<a class="contact-mail " href="mailto:manager@sylviatheresazayn.com">manager@sylviatheresazayn.com</a>
			</div>
			<div class="contact-title anim-3" style="margin-top: 7%;">Webmaster</div>
			<div class=" anim-4">
				<a class="contact-mail" href="mailto:david@sylviatheresazayn.com">david@sylviatheresazayn.com</a>
			</div>
		</div>
	</main>
	
</template>

<script>
	import PageHeader from "@/components/PageHeader.vue";
	import BackgroundPage from "@/components/BackgroundPage.vue";

	export default {
		components: {
			PageHeader, BackgroundPage
		}
	}
</script>

<style>
	.contact-bg-color {
		background-color: black !important;
		background: linear-gradient(171deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, var(--lastContact) 100%);
		background-repeat: repeat;
		background-size: cover;
		background-attachment: fixed;
		z-index: -9999;
	}
	
	.contact-bg-color-div {

		background: linear-gradient(171deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 60%, var(--lastContact) 100%);
		background-repeat: no-repeat;
		background-size: cover;
		z-index: -9999;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}	

	
	.contact-main {
		margin-top: 8%;
	}

	.contact-div div {
		animation-name: show-text;
		animation-duration: 2s;
		animation-timing-function: ease-in-out;
		animation-fill-mode: forwards;
		opacity: 0;
	}
	
	.anim-1{
		animation-delay: 0s;
	}
	.anim-2{
		animation-delay: 0.2s;
	}
	.anim-3{
		animation-delay: 0.4s;
	}
	.anim-4{	
		animation-delay: 0.6s;
	}

	@keyframes show-text {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	.contact-title {
		letter-spacing: 1px;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 1.875em;
		color: #FFFFFF;
	}
	
	.contact-name {
		letter-spacing: 1px;
		font-family: 'Poppins', sans-serif;
		font-weight: 400;
		font-size: 15px;
		line-height: 1.875em;
		font-style: italic;
		color: darkgrey;
	}
	
	.contact-phone {
		text-decoration: none;
	}
	
	.contact-phone:hover,
	.contact-phone:active {
		color: var(--lastAbout);
	}

	.contact-mail {
		/* font-family: 'Just Me Again Down Here'; */
		font-family: 'Bilbo', cursive;
		font-weight: 400;
		font-size: 22px;
		letter-spacing: 2px;
		/* color: #FFFFFF; */
		text-decoration: none;
		color: var(--lastAbout);
	}

	.contact-mail:hover,
	.contact-mail:active {
		color: var(--lastAbout);
	}
</style>
